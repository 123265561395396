import jquery from 'jquery';

declare global {
	interface Window {
		$: JQueryStatic;
		jQuery: JQueryStatic;
	}
}

export default window.$ = window.jQuery = jquery;
